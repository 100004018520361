<template>
    <v-flex class="w100">
        <v-sheet>
            <v-container align-center justify-start d-flex>
                <v-layout align-center justify-start column>
                    <Operating :kr_id="kr_id" />
                    <Intercom :ic_obj="this.ic_obj" />
                </v-layout>
            </v-container>
        </v-sheet>
    </v-flex>
</template>

<script>
import Operating from "./operating/Operating.vue";
// import Intercom from './intercom/Intercom.vue';
import Intercom from "@/components/common/Intercom/Intercom.vue";

export default {
    props: ["kr_id"],
    components: {
        Operating,
        Intercom,
    },
    data() {
        return {
            ic_obj: {},
        };
    },
    watch: {
        kr_id(value, oldValue) {
            this.ic_obj = {
                ic_parent: value,
                ic_parent_type: 1,
            };
        },
    },
};
</script>

<style></style>
